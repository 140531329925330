import React, { useState, useEffect } from 'react';

const Roads = (props) => {
  const {resort, timePerResort} = props;
  const [activeTab, setActiveTab] = useState(0);
  let intervalId;

  useEffect(() => {
    setActiveTab(0);  // restart
    clearInterval(intervalId);

    if (resort) {
      if (resort.roads.length > 0) {
        intervalId = setInterval(() => {
          setActiveTab((prevActiveTab) => (prevActiveTab + 1) % resort.roads.length);
        }, (timePerResort/resort.roads.length));
      }
    }
    return () => clearInterval(intervalId);
  }, [resort]);

  if (resort.roads.length < 1) {
    return null;
  }

  let response;
  try {
    response = <div className="Roads">
        <div className="Roads-top">
          <div className="label-2">ROADS</div>

          <div className="Roads-road-tabs">
            {resort.roads.map((road, index) => {
              return (
                <div key={index} className={index === activeTab ? 'active-road-tab' : 'road-tab'}></div>
              )
            })}
          </div>
        </div>
        <div>
          <div className="label-6">{resort.roads[activeTab].name}</div>
          <div className="label-5">{resort.roads[activeTab].restriction === "none" ? "No Restrictions" : resort.roads[activeTab].restriction }</div>
        </div>
      </div>
  } catch(err) {
    response = <div></div>
  }

  return response;
}

export default Roads;