import React, { useState, useEffect } from 'react';

const Forecast = (props) => {
  const {resort, timePerResort} = props;
  const [activeTab, setActiveTab] = useState(0);

  let tabs = [
    {
      title: "Now",
      content: resort.current_forecast_text
    }
  ]

  // It is possible that this doesn't exist. It happens once every 24 hours for some reason.
  if (resort.weather.length > 0) {
    tabs.push({
      title: resort.weather[0].title,
      content: resort.weather[0].text
    })
  }

  // It is possible that this doesn't exist. It happens once every 24 hours for some reason.
  if (resort.weather.length > 1) {
    tabs.push(
      {
        title: resort.weather[1].title,
        content: resort.weather[1].text
      }
    )
  }

  let intervalId;

  useEffect(() => {
    setActiveTab(0);  // restart
    clearInterval(intervalId);

    intervalId = setInterval(() => {
      setActiveTab((prevActiveTab) => (prevActiveTab + 1) % tabs.length);
    }, (timePerResort/tabs.length));
    return () => clearInterval(intervalId);
  }, [resort]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    clearInterval(intervalId);
  }

  return (
    <div className="Forecast">
      <div className="label-2">Resort Forecast</div>
      <div className="Forecast-inner">
        <div className="Forecast-tab-buttons">
          {tabs.map((tab, index) => {
            return (
              <div key={index} onClick={() => handleTabClick(index)} className={index === activeTab ? 'active-tab' : 'tab'}>{tab.title}</div>
            )
          })}
        </div>
        <div className="Forecast-tab-content">
          <div className="body-text">{tabs[activeTab].content}</div>
        </div>
      </div>
    </div>
  )
}

export default Forecast;