import logo from './logo.svg';
import './App.css';
import './Resorts.css';
import Roads from './components/Roads.js';
import LastThreeSnowfalls from './components/LastThreeSnowfalls.js';
import Forecast from './components/Forecast.js';
import Webcam from './components/Webcam.js';
import SnowAmount from './components/SnowAmount.js';
import React, { useState, useEffect, useRef } from 'react';
import fetchResorts from './fetchResorts.js';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as UPDLogo } from './upd.svg';


const timePerResort = 20_000;  // 20 seconds
const openTime = 'T03:00:00.000-07:00';  // 3am MST seems reasonable
const closeTime = 'T21:00:00.000-07:00'; // 9pm MST seems reasonable
const nowUTC = Date.now();
let open = [];
let closed = [];
const fullCyclesBeforeRefresh = 3;

function calculateDaysUntilOpen(openDate) {
  // openDate is a string at this point that is only a date, not a time, must add the time.

  const openeningDate = new Date(`${openDate}${openTime}`);  // must be converted to a full time.
  const today = new Date();
  const daysUntilOpen = Math.round((openeningDate - today) / (1000 * 3600 * 24));

  if (daysUntilOpen === 1) {
    return "Tomorrow";
  }

  if (daysUntilOpen > 1) {
    return `${daysUntilOpen} days`;
  }

  // must be 0 or a negative value some how.
  return "Soon";
}

function isWithinThirtyDays(dateAsString) {
  const then = new Date(dateAsString);
  const now = new Date();

  const msBetweenDates = Math.abs(then.getTime() - now.getTime());

  // 👇️ convert ms to days                 hour   min  sec   ms
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

  if (daysBetweenDates < 30) {
    return true;
  } else {
    return false;
  }
}

function generateClosedResortText(resort) {
  // this is a complex function that returns the text that shows for a closed resort.
  // if the resort is going to open within 30 days, show the amount of days that the resort opens in and the opening date.
  // if the resort closed within 30 days ago, show the closed date.
  const nowUTC = new Date().getTime();  // getTime returns the timestamp, not just the string.

  if (resort.anticipated_opening_date && resort.anticipated_opening_date !== null) {
    // The resort is closed and there is an opening date.
    if (resort.anticipated_closing_date && resort.anticipated_closing_date !== null) {
      // The resort is closed, there is an opening date and a closing date. This sounds like post season because a closing date is not usually provided until near end of season.
      if (nowUTC > Date.parse(`${resort.anticipated_closing_date}${closeTime}`)) {
        // The resort is closed, there is an opening and a closing date, the closing time is in the past. This is the state after a season, aka post season.
        return <span className={resort.utahPowderDay ? 'daysUntilOpenUtahPowderDay' : 'daysUntilOpen'}>{ new Date(`${resort.anticipated_closing_date}${closeTime}`).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric'})}</span>;
      } else {
        // The resort is closed, there is an opening and a closing date, the closing date is in the future. ???
        // But if we are in this function then the resort is closed.
        // This block is highly unlikely because the closing date isn't usually entered until a month before it closes.
        // This block sounds impossible to land in.
        if (nowUTC < Date.parse(`${resort.anticipated_opening_date}${openTime}`)) {
          // The resort is closed, there is an opening date, there is a closing date, the opening date is in the future, the closing date is in the future.
          // This is highly likely that we will know the closing date before the resort is even open but still just return a string about when the resort will open.
          // This is pre season.
          return <span className={resort.utahPowderDay ? 'daysUntilOpenUtahPowderDay' : 'daysUntilOpen'}>{ calculateDaysUntilOpen(resort.anticipated_opening_date)} | {new Date(`${resort.anticipated_opening_date}${openTime}`).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric'})}</span>;
        } else {
          return null;
        }
      }
    } else {
      // The resort is closed, there is an opening date, there is NO closing date.
      // This must mean that we are in pre season.
      if (nowUTC < Date.parse(`${resort.anticipated_opening_date}${openTime}`)) {
        // The resort is closed, there is an opening date, there is no closing date, the opening date is in the future.
        // This is pre season.
        return <span className={resort.utahPowderDay ? 'daysUntilOpenUtahPowderDay' : 'daysUntilOpen'}>{ calculateDaysUntilOpen(resort.anticipated_opening_date)} | {new Date(`${resort.anticipated_opening_date}${openTime}`).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric'})}</span>;
      } else {
        // The resort is closed, there is an opening date, there is no closing date, the opening date is in the past. ???
        // Highly unlikely we will land in this block.
        // That would mean the resort should be open. This block should be impossible to land in.
        return null;
      }
    }
  } else {
    // The resort is closed, there is no opening date yet.
    if (resort.anticipated_closing_date && resort.anticipated_closing_date !== null) {
      // The resort is closed, there is no opening date, there is a closing date. ???
      // This block is highly unlikely.
      // I can't think of a scenario where there would only be a closing date and not an opening date.
      if (nowUTC > Date.parse(`${resort.anticipated_closing_date}${closeTime}`)) {
        // The resort is closed, there is no opening date, there IS a closing date, the closing date is in the past.
        // This is odd but sounds like post season.
        return <span className={resort.utahPowderDay ? 'daysUntilOpenUtahPowderDay' : 'daysUntilOpen'}>{ new Date(`${resort.anticipated_closing_date}${closeTime}`).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric'})}</span>;
      } else {
        // The resort is closed, there is no opening date, there is a closing date, the closing date is in the future. ???
        // That would mean the resort is open???
        // I believe this block is impossible to fall into.
        return null;
      }
    } else {
      // The resort is closed, there is no opening date, there is no closing date. This really is TBA. This is in the summer before we know when the resort will open for the following season.
      return <span className={resort.utahPowderDay ? 'daysUntilOpenUtahPowderDay' : 'daysUntilOpen'}>TBA</span>;
    }
  }
}

const RemoteSVGImage = ({ url }) => {
  // This is failing because skiutah needs to grant my dev server access to the images.

  const [svgContent, setSvgContent] = useState(null);

  // if you try to load the image from www.skiutah.com you'll get CORS issues.
  const localUrl = url.replace("https://www.skiutah.com/", "https://skiosk.skiutah.com/")

  useEffect(() => {
    const fetchSVG = async () => {
      try {
        const response = await fetch(localUrl);
        const svgText = await response.text();
        setSvgContent(svgText);
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    };

    fetchSVG();
  }, [url]);

  return (
    <div>
      {svgContent ? (
        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};


const ResortDetails = ({resort}) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // this is so we can animate the item in and out.
    const loadIn = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    const loadOut = setTimeout(() => {
      setIsVisible(false);
    }, (timePerResort - 1000));  // add the class to hide it with one second left, this needs to match the duration of the transition in the css

    return () => {
      clearTimeout(loadIn);
      clearTimeout(loadOut);
    }
  }, [resort]);

  return (
    <div className={`ResortDetails ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="ResortDetails-section-1">
        <div className="Resort-details-title">{resort.short_name}</div>
        <div className="Resort-details-logo">
          <RemoteSVGImage url={resort.service_icon_svg_url} />
        </div>
        <div className="Resort-details-last-updated"><b>updated:</b> {new Date(resort.snow_report_updated).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}</div>
      </div>
      <div className="ResortDetails-section-2">
        <SnowAmount resort={resort} />
      </div>
      <div className="ResortDetails-section-3">
          <LastThreeSnowfalls resort={resort} />
          <Roads resort={resort} timePerResort={timePerResort} />
      </div>

      <div className="Resorts-detail-section-4">
        <Forecast resort={resort} timePerResort={timePerResort} />
        <Webcam resort={resort}/>
      </div>
    </div>
  );
}

function GlobalUtahPowderDay() {
  return (
      <div className="GlobalUtahPowderDay">
        <UPDLogo className="GlobalUtahPowderDay-upd-logo" />
        <div>Today is a <b className="upd-red">UTAH POWDER DAY</b><br/><b>12"+</b> of Snow in <b>24 Hours</b></div>
      </div>
  )
}

function LogoContainer() {
  return (
    <div className="LogoContainer">
      <Logo className="LogoContainer-logo" />
    </div>
  );
}

function ResortsTopRow() {
  return (
    <div className="ResortsTopRow">
      <div className="Resort-name-column">Resort</div>
      <div className="Resort-new-column">New</div>
      <div className="Resort-base-column">Base</div>
    </div>
  );
}

const ResortRowOpen = ({resort}) => {
  return (
    <>
      <div className="Resort-name-column">
        {resort.utahPowderDay && <UPDLogo className="ResortRowOpen-upd-logo" />}
        <div>
          {resort.short_name}
        </div>
      </div>
      <div className="Resort-new-column"><div className={`label-3${resort.utahPowderDay ? ' upd-red' : '' }`}>{resort.new_snow_24}"</div></div>
      <div className="Resort-base-column"><div className="label-3">{resort.snow_base}"</div></div>
      { (resort.anticipated_closing_date && resort.anticipated_closing_date !== null && isWithinThirtyDays(resort.anticipated_closing_date)) ? <div className="closingDate">CLOSING {new Date(`${resort.anticipated_closing_date}${closeTime}`).toLocaleDateString('en-us', {timeZone: 'MST', month: 'short', day: 'numeric', year: 'numeric'})}</div> : null}
    </>
  )
}

const ResortRowClosed = ({resort}) => {
  return (
    <>
      <div className="Resort-name-column">
        {resort.utahPowderDay && <UPDLogo className="ResortRowOpen-upd-logo" />}
        <div>
        {resort.short_name}
        </div>
      </div>
      <div className="Resort-closed-column">
        <div className="label-4">{resort.anticipated_closing_date && new Date().getTime() > Date.parse(`${resort.anticipated_closing_date}${closeTime}`) ? 'CLOSED' : 'OPENING' }</div>
        <div className="label-5">{generateClosedResortText(resort)}</div>
      </div>
    </>
  );
}

const ProgressBar = (props) => {
  const {resort, activeResort} = props;

  if (resort.resort_id === activeResort.resort_id) {
    return (
      <div className="ProgressBar" key={resort.resort_id}>
        <div className="ProgressBar-dot"></div>
      </div>
    )
  } else {
    return null;
  }
}

const Loading = () => {
  return (
    <div className="loading">
      <div>
        <Logo />
        <div className="text-center">Fetching the latest!</div>
        <div className="DarkLoading"></div>
      </div>
    </div>
  )
}

const Transition = ({isActive}) => {
  return <div className={`${isActive ? "is-active " : ""}Transition`}></div>
}

function App() {
  const [resorts, setResorts] = useState([]);
  const [hasRun, setHasRun] = useState(false);
  const [activeResort, setActiveResort] = useState(null);
  const [globalUtahPowderDay, setGlobalUtahPowderDay] = useState(false);
  const [cycleCount, setCycleCount] = useState(0);

  useEffect(() => {
    let tvUsername = null;
    if (window.location.pathname !== '/') {  // then it must contain a username
      // leave it alone, if not extract the username
      tvUsername = window.location.pathname.split('/')[2];  // /u/sts/
    }

    fetchResorts(tvUsername).then((data) => {
      setResorts(data);
    });
  }, []);

  const itemRefs = useRef([]);

  useEffect(() => {
    if (resorts.length >= 0) {
      // This can't run until all of the resorts data is ready.
      setActiveResort(resorts[0]);

      resorts.forEach((resort) => {
        // this is where I determine if the resort is open or not.
        resort.isOpen = false;

        let openTimeUTC = null;
        let closingTimeUTC = null;
        let openingTimeUTC = null;

        if (resort['anticipated_opening_date'] !== null) {
          openingTimeUTC = Date.parse(`${resort['anticipated_opening_date']}${openTime}`);
          // This is say Nov 20, 2022 6am local which is Nov 20, 2022 11am UTC, we're going to compare it to another UTC timestamp.

          // There is an opening date.
          if ( nowUTC >= openingTimeUTC ) {
            // There is an opening date, it is now or in the past.
            if ( resort['anticipated_closing_date'] !== null) {
              closingTimeUTC = Date.parse(`${resort['anticipated_closing_date']}${closeTime}`);
              // There is an opening date, it is now or in the past, there is a closing date.
              if ( nowUTC < closingTimeUTC ) {
                // There is an opening date, it is now or in the past, there is a closing date. The closing time is in the future.
                // The resort is open.
                resort.isOpen = true;
                open.push(resort);
              } else {
                // There is an opening date, it is now or in the past, there is a closing date. The closing time now or in the past.
                // The resort is closed.
                resort.isOpen = false;
                closed.push(resort);
              }
            } else {
              // There is an opening date, it is now or in the past.
              // There is no closing date. The resort is open.
              resort.isOpen = true;
              open.push(resort);
            }
          } else {
            // There is an opening date, that date is in the future.
            // The resort is not open yet, so it is closed.
            // It does not matter if there is a closing date or not.
            resort.isOpen = false;
            closed.push(resort);
          }
        } else {
          // There is no opening date. There for it is closed and the opening date should show as "TBA"
          // It does not matter if there is a closing date.
          resort.isOpen = false;
          closed.push(resort);
        }

        // this is where I determine if it's a powder day.
        resort.utahPowderDay = false;

        if (resort['new_snow_24'] >= 12) {
          resort.utahPowderDay = true;
          setGlobalUtahPowderDay(true);
        }
      });

    }
  }, [resorts])

  const findIndexById = (id) => {
    return resorts.findIndex(item => item.resort_id === id);
  }

  const getIndexById = (id) => {
    const index = findIndexById(id);
    return index;
  }

  function scrollToitem(index) {
    itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  }

  function renderResortDetails() {
    return (
      resorts.map((resort, index) => {
        if (resort.resort_id === activeResort.resort_id) {
          return(<ResortDetails key={index} resort={resort} />);
        } else {
          return null;
        }
      })
    );
  }

  function renderResortList() {
    return (
      <div className={globalUtahPowderDay ? "Resorts-scrollable-w-upd" : "Resorts-scrollable"}>
        {resorts.map((resort, index) => {
          return (
            <div
              // ref={ref => (itemRefs.current[index] = ref)}
              key={index}
              className={`${resort.utahPowderDay ? 'resort-list-is-powder-day ' : ''}${resort.resort_id === activeResort.resort_id ? 'Resorts-list-item-active' : 'Resorts-list-item'}`}
              onClick={() => {setActiveResort(resort);}}
              >
              {resort.isOpen ? <ResortRowOpen resort={resort} /> : <ResortRowClosed resort={resort} />}
              <ProgressBar resort={resort} activeResort={activeResort} />
            </div>
          )
        })}
      </div>
    );
  }

  useEffect(() => {
    // everytime the activeresort is updated.
    if (activeResort) {

      // active resort is always 0
      // const activeResortIndex = getIndexById(activeResort.resort_id);
      let interval;

      if (cycleCount/fullCyclesBeforeRefresh === (resorts.length)) { // only refresh after cycling three times
        // don't try to increment and set the active item.
        interval = setInterval(() => {
          // scrollToitem(activeResortIndex + 1);
          window.location.reload();
        }, timePerResort);
      } else {
        interval = setInterval(() => {
          // scrollToitem(activeResortIndex + 1);
          const itemToPop = resorts.shift();
          resorts.push(itemToPop);
          setActiveResort(resorts[0]);
        }, timePerResort);
      }
      setCycleCount(cycleCount => cycleCount + 1);
      return () => clearInterval(interval);
    }
  }, [activeResort])

  if (activeResort) {
    return (
      <div className="Resorts">
        <div className="Resorts-list">
          <LogoContainer />
          {globalUtahPowderDay ? <GlobalUtahPowderDay /> : null}
          <ResortsTopRow />
          {renderResortList()}
        </div>
        {/*{renderResortDetails()}*/}
        <ResortDetails resort={activeResort} />
      </div>
    );
  } else {
    return (<Loading />);
  }
}

export default App;
