import React, { useState, useEffect } from 'react';

const Webcam = ({resort}) => {
  const [cam, setCam] = useState(null);

  useEffect(() => {
    if (resort.cams.length > 0) {
      setCam(resort.cams[0]);
    } else {
      setCam(null);
    }
  }, [resort]);

  if (cam) {
    return (
      <div className="Webcam" key={cam.title.replace(' ', '')}>
          <div className="label-2">{cam.title}</div>
          <div className="Webcam-container">
            { cam.url.includes('.mp4') ? (
              <video className="Webcam-video" autoPlay><source src={cam.url} type="video/mp4" /></video>
            ) : (
              <img src={cam.url} className="Webcam-image" />
            )}
          </div>
      </div>
    );
  }
  return null;
}

export default Webcam;