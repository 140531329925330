import React from 'react';

const SnowAmount = ({resort}) => {
  return (
    <div className="SnowAmount">
      {resort.new_snow_overnight > 0 &&
        <div className="text-center">
          <div className="label-1">Overnight</div>
          <div className="SnowAmount-amount">{resort.new_snow_overnight}"</div>
        </div>
      }
      <div className="text-center">
        <div className="label-1">24 Hours</div>
        <div className="SnowAmount-amount">{resort.new_snow_24}"</div>
      </div>
      <div className="text-center">
        <div className="label-1">Base</div>
        <div className="SnowAmount-amount">{resort.snow_base}"</div>
      </div>
      <div className="text-center">
        <div className="label-1">Year-To-Date</div>
        <div className="SnowAmount-amount">{resort.snowfall_ytd}"</div>
      </div>
    </div>
  );
}

export default SnowAmount;