import { fetchHeaders, networkError, rootDomain } from './Constants.js';

export default async function fetchResorts(tvUsername) {

	let resortHasMoreThan12InLast24hours = false;

	let url = `${rootDomain()}/feed/resorts/`;
	if (tvUsername) {
		url = `${rootDomain()}/feed/resorts-filter/${tvUsername}/`
	}

	try {
		const resp = await fetch(url, {
			headers: fetchHeaders()
		});

		const data = await resp.json();

		data.every((resort) => {
			if (resort.new_snow_24 >= 12) {
				resortHasMoreThan12InLast24hours = true;
				return false;
			}
			return true;
		});

		// First sort by alphabetical
		data.sort((a, b) => {
			if (a.short_name > b.short_name) {
				return 1;
			} else {
				return -1;
			}
		});

		// if (resortHasMoreThan12InLast24hours) {
		// 	// yes, we just sorted right above this, but that will flip the alphabetical "A" to the top
		// 	data.sort((a, b) => {
		// 		if (a.short_name < b.short_name) {
		// 			return 1;
		// 		} else {
		// 			return -1;
		// 		}
		// 	});
		// 	// Then sort by inches in last 24 hours
		// 	data.sort((a, b) => {
		// 		if (a.new_snow_24 < b.new_snow_24) {
		// 			return 1;
		// 		} else {
		// 			return -1;
		// 		}
		// 	});
		// }

		return data;

	} catch (error) {
		networkError();
	} finally {
		// any cleanup?
	}

	return [];
}