import React from 'react';

const LastThreeSnowfalls = ({resort}) => {
  if (resort.snowfall.length === 0) {
    return null;
  }

  function friendlyDateString(dateString) {
    const date = new Date(dateString);

    // Define an array of month names for reference
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Extract the month and day components
    const month = monthNames[date.getMonth()];
    const day = date.getDate();

    // Create the desired output format
    const formattedDate = `${month} ${day}`;
    return formattedDate;
  }

  return (
    <div className="LastThreeSnowfalls">
      <div className="label-2">LAST THREE SNOWFALLS</div>
      <div className="LastThreeSnowfalls-inner">
        {resort.snowfall.slice(0, 3).map((snow, index) => {
          return (
            <div key={index}>
              <div className="text-center">
                <div className="label-1">{friendlyDateString(snow.date_start)}</div>
                <div className="LastThreeSnowfalls-inner-amount">{snow.snowfall}"</div>
              </div>
            </div>
          )
        })}
        </div>
      </div>
  );
}

export default LastThreeSnowfalls;