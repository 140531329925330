const prod = true;

export const rootDomain = () => {
	// const devDomain = "https://supertopsecret.ngrok.io"; // use for skiosk main url testing dev
	const devDomain = "http://localhost:8000"; // use for local dev
	const prodDomain = "https://skiosk.skiutah.com";
	return prod ? prodDomain : devDomain;
}

export const fetchHeaders = () => {
	const devToken = "13f6d0b5faad0bd08121da5f173645aed8c6d4dc";
	const prodToken = "a27853c0f93e30f52898744f8c4add2553157b8c" // sts user
	return (
		{
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Token ${prod ? prodToken : devToken}`,
		}
	)
};

export const networkError = () => {
	return alert("Is there any internet? Try again by refreshing the page!");
}